import React, { useContext, useEffect, useRef, useState } from "react";
import "./PaymentFailed.css";
import CartSubtitle from "../ComponentsCheckoutPage/Components/CartSubtitle/CartSubtitle";
import CheckoutFooter from "../ComponentsCheckoutPage/Components/CheckoutFooter/CheckoutFooter";
import UniversalCheckoutHeader from "../../UniversalComponents/CheckoutHeader/CheckoutHeader";
import {
  ApiResponseGetPaymentLink,
  ApiResponseGetStoreItems,
  ItemDetailsData,
  ItemDetailsRecommended,
  ResponseCartItem,
} from "../../Utils/DefinitionsInterfaces";
import axios, { AxiosResponse } from "axios";
import {
  MIN_MAX_REFETCH,
  MAX_MAX_REFETCH,
  merchantLogoString,
} from "../../Utils/Constants";
import {
  fetchItemDetails,
  fetchRecommendedItems,
  getOrderStatus,
  getPaymentLinkForDoubtClearing,
  getPaymentLinkForNFOSessions,
  getPaymentLinkForOrder,
} from "../../Utils/CheckoutAPIs/CheckoutAPIs";
import { AuthContext, CartContext } from "../../App";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fAnalytics,
  logFirebaseEvent,
} from "../../Utils/firebase/firebaseConfig";
import { LogEventBody, logEvent } from "../../Utils/api/auth";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("order_id");
  const itemIds = urlParams.get("item_ids");
  const [loading, setLoading] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState<ItemDetailsData[]>([]);
  const {
    auth: {
      student_username,

      grade,

      school,
    },
  } = useContext(AuthContext);
  const undiqueId = sessionStorage.getItem("uniqueId");
  const [recommendedItems, setRecommendedItems] = useState<
    ItemDetailsRecommended[]
  >([]);
  const [allItemDetailsDB, setAllItemDetailsDB] = useState<ItemDetailsData[]>(
    []
  );
  const [checkoutItemIds, setCheckoutItemIds] = useState<number[]>([]);
  const nfoSessionseventId = sessionStorage.getItem("nfoSessionseventId");

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };
  const verifyPayment = async (orderId: string) => {
    try {
      const response = await getOrderStatus(orderId);
      if (response) {
        //@ts-ignore
        if (response.data.data.paymentStatus === "success") {
          console.log("Payment Successful");
          return true;
          //@ts-ignore
        } else if (response.data.data.paymentStatus === "retry") {
          console.log("Retry payment");
          return false;
          //@ts-ignore
        } else if (
          //@ts-ignore
          response.data.data.paymentStatus === "payment_processing_error"
        ) {
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const paymentCallbackFunction = async () => {
    if (orderId) {
      console.log("Order id =", orderId);
      setLoading(true);
      const orderStatus = await verifyPayment(orderId);
      console.log("order status = ", orderStatus);
      if (orderStatus) {
        if (itemIds) {
          navigate(`/PaymentSuccessful/?item_ids=${itemIds}`);
        } else {
          navigate(`/PaymentSuccessful`);
        }
      } else {
        //reverifying payment
        const orderStatus2ndTime = await verifyPayment(orderId);
        if (orderStatus2ndTime) {
          if (itemIds) {
            navigate(`/PaymentSuccessful/?item_ids=${itemIds}`);
          } else {
            navigate(`/PaymentSuccessful`);
          }
        } else {
          //reverifying 3rd time as 3 retries allowed by verify payment
          const orderStatus3rdTime = await verifyPayment(orderId);
          if (orderStatus3rdTime) {
            if (itemIds) {
              navigate(`/PaymentSuccessful/?item_ids=${itemIds}`);
            } else {
              navigate(`/PaymentSuccessful`);
            }
          } else {
            if (itemIds) {
              navigate(`/PaymentFailed/?item_ids=${itemIds}`);
            } else {
              navigate(`/PaymentFailed`);
            }
          }
        }
      }
    } else {
      console.log("No order id. So, no redirection.");
    }
  };

  // const getAndSetAllItemData = async (): Promise<ItemDetailsData[]> => {
  //   let allItems = await fetchRecommendedItems();
  //   setAllItemDetailsDB(allItems);
  //   return allItems;
  // };

  const getAndSetAllItemsDB = async (
    itemsIdsAr: number[]
  ): Promise<ItemDetailsData[]> => {
    let tempAllItems = await fetchRecommendedItems();
    let allItems: ItemDetailsData[] = [];
    tempAllItems.map((item) => {
      if (item.selling_price !== 0) {
        allItems.push(item);
      }
    });
    for (let a = 0; a < itemsIdsAr.length; a++) {
      let alreadyPresent = false;
      for (let i = 0; !alreadyPresent && i < allItems.length; i++) {
        if (allItems[i].id === itemsIdsAr[a]) {
          alreadyPresent = true;
        }
      }
      if (!alreadyPresent) {
        let newItem = await fetchItemDetails(itemsIdsAr[a]);
        if (newItem && newItem.length > 0) {
          allItems.push(newItem[0]);
        }
      }
    }
    setAllItemDetailsDB(allItems);
    return allItems;
  };

  const populateFullCart = (
    allItems: ItemDetailsData[],
    itemsIdsAr: number[]
  ) => {
    let tempCheckItems: ItemDetailsData[] = [];
    let tempRecoItems: ItemDetailsData[] = [];
    allItems.map((item) => {
      if (itemsIdsAr.includes(item.id)) {
        tempCheckItems.push(item);
      } else {
        tempRecoItems.push(item);
      }
    });
    setCheckoutItems(tempCheckItems);
    setRecommendedItems(tempRecoItems);
  };

  const handleRemoveItem = (itemId: number) => {
    setCheckoutItemIds((prevItems) =>
      prevItems.filter((currId) => currId !== itemId)
    );
    setCheckoutItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
    let tempRecoItems: ItemDetailsData[] = [];
    let foundItem = false;
    for (let i = 0; !foundItem && i < allItemDetailsDB.length; i++) {
      if (allItemDetailsDB[i].id === itemId) {
        tempRecoItems.push(allItemDetailsDB[i]);
        foundItem = true;
      }
    }
    if (tempRecoItems.length > 0)
      setRecommendedItems((prevItems) => [...prevItems, tempRecoItems[0]]);
  };

  const handleAddItemFromRecommended = (itemId: number) => {
    setCheckoutItemIds((prevIds) => [...prevIds, itemId]);
    setRecommendedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
    let tempCartItems: ItemDetailsData[] = [];
    let foundItem = false;
    for (let i = 0; !foundItem && i < allItemDetailsDB.length; i++) {
      if (allItemDetailsDB[i].id === itemId) {
        tempCartItems.push(allItemDetailsDB[i]);
        foundItem = true;
      }
    }
    if (tempCartItems.length > 0)
      setCheckoutItems((prevItems) => [...prevItems, tempCartItems[0]]);
  };

  useEffect(() => {
    paymentCallbackFunction();
    if (itemIds && itemIds.length > 0) {
      let itemsIdsAr: number[] = [];
      itemsIdsAr = itemIds.split("-").map(Number);
      getAndSetAllItemsDB(itemsIdsAr).then((allItems) => {
        //now add all checkout items
        setCheckoutItemIds(itemsIdsAr);
        populateFullCart(allItems, itemsIdsAr);
      });
    }
  }, []);

  const getSessionIdBD = async (
    orderIds: number[]
  ): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
    let orderNote = "";
    for (let i = 0; i < orderIds.length; i++) {
      orderNote += orderIds[i];
      if (i + 1 < orderIds.length) orderNote += ",";
    }
    // orderNote = "27,28";
    const RequestData = {
      orderNote: orderNote,
    };
    let responseGetLinks = await getPaymentLinkForOrder(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const getSessionForDoubtClearing = async (
    orderIds: string
  ): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
    let orderNote = "";
    for (let i = 0; i < orderIds.length; i++) {
      orderNote += orderIds[i];
      if (i + 1 < orderIds.length) orderNote += ",";
    }
    // orderNote = "27,28";
    const RequestData = {
      uniqueBookingID: undiqueId,
    };
    let responseGetLinks = await getPaymentLinkForDoubtClearing(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const getSessionForNFOSessionsLink = async (): Promise<
    AxiosResponse<ApiResponseGetPaymentLink>
  > => {
    const RequestData = {
      studentID: student_username,
      eventID: nfoSessionseventId,
    };
    let responseGetLinks = await getPaymentLinkForNFOSessions(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const handlePaymentClick = async (orderIds: number[]) => {
    let itemIdsString: string = "";
    for (let i = 0; i < checkoutItems.length; i++) {
      itemIdsString += checkoutItems[i].id;
      if (i + 1 !== checkoutItems.length) {
        itemIdsString += "-";
      }
    }
    try {
      setLoading(true);

      const response = undiqueId
        ? await getSessionForDoubtClearing(undiqueId)
        : itemIds === "29"
        ? await getSessionForNFOSessionsLink()
        : await getSessionIdBD(orderIds);
      //@ts-ignore
      console.log(response.data.data.links[1].parameters.bdorderid);
      //@ts-ignore
      if (response.data.status == "Success") {
        var flow_config = {
          //@ts-ignore
          merchantId: response.data.data.links[1].parameters.mercid,
          //@ts-ignore
          bdOrderId: response.data.data.links[1].parameters.bdorderid,
          //@ts-ignore
          authToken: response.data.data.links[1].headers.authorization,
          childWindow: true,
          //@ts-ignore
          // returnUrl: `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?order_id=${response.data.data.orderID}&item_ids=${itemIdsString}`,
          returnUrl: `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?order_id=${response.data.data.orderID}&item_ids=${itemIdsString}&userName=${student_username}`,
          retryCount: 3,
          prefs: {
            payment_categories: ["card", "nb", "upi"],
            // allowed_bins: ["540000", "400000"],
          },
          netBanking: {
            showPopularBanks: "Y",
            popularBanks: ["Kotak Bank", " AXIS Bank [Retail]"],
          },
        };

        var responseHandler = function (txn: {
          status: string;
          response: string;
        }) {
          console.log("callback received status:: ", txn.status);
          console.log("callback received response:: ", txn.response);
        };

        var theme_config = {
          sdkPrimaryColor: "#023b8f",
          sdkAccentColor: "#023b8f",
          sdkBackgroundColor: "#FFF",
          sdkBannerColor: "#FFF",
        };

        var config = {
          responseHandler: responseHandler,
          merchantLogo: merchantLogoString,
          flowConfig: flow_config,
          flowType: "payments",
          themeConfig: theme_config,
        };
        //@ts-ignore
        if (window.loadBillDeskSdk) {
          // Assuming you have a config object
          //@ts-ignore
          window.loadBillDeskSdk(config);
          setLoading(false);
        }
      }
    } catch (err) {
      openInSameTab(
        "https://nfoportal.nationalfinanceolympiad.com/doubt-clearing"
      );
    }
  };

  useEffect(() => {
    logFirebaseEvent("user_failed_payment", {
      user_id_custom: student_username,
      grade: grade,
      school: school,
      itemName: checkoutItems[0]?.name,
    });
    console.log(checkoutItems[0]?.name);

    const logBody: LogEventBody = {
      studentUserID: student_username,
      eventName: "payment_failed",
      productIDs: checkoutItemIds,
    };
    logEvent(logBody);
  }, []);

  return (
    <div className="checkout-summary-page">
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 100,
            background: "rgba(0,0,0,0.7)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="checkout">
        <UniversalCheckoutHeader
          titleText="Payment Failed"
          subtitleText="Please retry payment"
          imageUrl="/img/group-12370.png"
          titleTextClass="universal-text-wrapper-red"
          subtitleTextClass="universal-div-red"
        />
        <CartSubtitle
          stateLoading={loading}
          stateCheckoutItems={checkoutItems}
          stateRecommendedItems={recommendedItems}
          cbRemoveProduct={handleRemoveItem}
          cbAddRecommended={handleAddItemFromRecommended}
          cbHandlePaymentClick={handlePaymentClick}
        />
        <CheckoutFooter />
      </div>
    </div>
  );
};

export default PaymentFailed;
