import React, { useContext, useEffect, useRef, useState } from "react";
import "./CheckoutPage.css";
import CartSubtitle from "./Components/CartSubtitle/CartSubtitle";
import CheckoutFooter from "./Components/CheckoutFooter/CheckoutFooter";
import UniversalCheckoutHeader from "../../UniversalComponents/CheckoutHeader/CheckoutHeader";
import api from "../../Utils/axios/axiosMW";
import axios, { AxiosResponse } from "axios";
import {
  ApiResponseGetItemDetails,
  ApiResponseGetPaymentLink,
  ApiResponseGetStoreItems,
  ItemDetailsData,
  ItemDetailsRecommended,
  ResponseCartItem,
} from "../../Utils/DefinitionsInterfaces";
import {
  MAX_MAX_REFETCH,
  MIN_MAX_REFETCH,
  merchantLogoString,
} from "../../Utils/Constants";
import {
  fetchItemDetails,
  fetchRecommendedItems,
  getOrderStatus,
  getPaymentLinkForDoubtClearing,
  getPaymentLinkForNFOSessions,
  getPaymentLinkForOrder,
} from "../../Utils/CheckoutAPIs/CheckoutAPIs";
import { AuthContext, CartContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fAnalytics,
  logFirebaseEvent,
} from "../../Utils/firebase/firebaseConfig";
import { LogEventBody, logEvent } from "../../Utils/api/auth";
import "../../../src/UniversalStyles.css";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("order_id");
  const itemIds = urlParams.get("item_ids");
  const itemId = urlParams.get("itemId");
  const nfoCheckoutAccessToken = urlParams.get("token");
  const [showBookingFailed, setShowBookingFailed] = useState(false);
  if (nfoCheckoutAccessToken) {
    localStorage.setItem("nfoCheckoutAccessToken", nfoCheckoutAccessToken);
  }

  const [checkoutItems, setCheckoutItems] = useState<ItemDetailsData[]>([]);
  const [recommendedItems, setRecommendedItems] = useState<
    ItemDetailsRecommended[]
  >([]);
  const [allItemDetailsDB, setAllItemDetailsDB] = useState<ItemDetailsData[]>(
    []
  );
  const [checkoutItemIds, setCheckoutItemIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    auth: { student_username, grade, school },
  } = useContext(AuthContext);
  const undiqueId = sessionStorage.getItem("uniqueId");
  const nfoSessionseventId = sessionStorage.getItem("nfoSessionseventId");
  console.log({ nfoSessionseventId });

  const verifyPayment = async (orderId: string) => {
    try {
      const response = await getOrderStatus(orderId);
      if (response) {
        //@ts-ignore
        if (response.data.data.paymentStatus === "success") {
          console.log("Payment Successful");
          return true;
          //@ts-ignore
        } else if (response.data.data.paymentStatus === "retry") {
          console.log("Retry payment");
          return false;
          //@ts-ignore
        } else if (
          //@ts-ignore
          response.data.data.paymentStatus === "payment_processing_error"
        ) {
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const paymentCallbackFunction = async () => {
    console.log("Order id =", orderId);
    if (orderId) {
      console.log("Order id =", orderId);
      setLoading(true);
      const orderStatus = await verifyPayment(orderId);
      console.log("order status = ", orderStatus);
      if (orderStatus) {
        setLoading(false);
        if (itemIds) {
          navigate(`/PaymentSuccessful/?item_ids=${itemIds}`, {
            state: { orderId: orderId, itemId: itemIds },
          });
        } else {
          navigate(`/PaymentSuccessful`);
        }
      } else {
        //reverifying payment
        const orderStatus2ndTime = await verifyPayment(orderId);
        if (orderStatus2ndTime) {
          setLoading(false);
          if (itemIds) {
            navigate(`/PaymentSuccessful/?item_ids=${itemIds}`, {
              state: { orderId: orderId, itemId: itemIds },
            });
          } else {
            navigate(`/PaymentSuccessful`);
          }
        } else {
          //reverifying 3rd time as 3 retries allowed by verify payment
          const orderStatus3rdTime = await verifyPayment(orderId);
          if (orderStatus3rdTime) {
            setLoading(false);
            if (itemIds) {
              navigate(`/PaymentSuccessful/?item_ids=${itemIds}`, {
                state: { orderId: orderId, itemId: itemIds },
              });
            } else {
              navigate(`/PaymentSuccessful`);
            }
          } else {
            if (itemIds) {
              navigate(`/PaymentFailed/?item_ids=${itemIds}`);
            } else {
              navigate(`/PaymentFailed`);
            }
          }
        }
      }
    }
  };

  console.log(undiqueId);

  const getAndSetAllItemsDB = async (
    itemId: number
  ): Promise<ItemDetailsData[]> => {
    let tempAllItems = await fetchRecommendedItems();
    let allItems: ItemDetailsData[] = [];
    tempAllItems.map((item) => {
      if (item.selling_price !== 0) {
        allItems.push(item);
      }
    });
    let alreadyPresent = false;
    for (let i = 0; !alreadyPresent && i < allItems.length; i++) {
      if (allItems[i].id === itemId) {
        alreadyPresent = true;
      }
    }
    if (!alreadyPresent) {
      let mainItem = await fetchItemDetails(itemId);
      if (mainItem && mainItem.length > 0) {
        allItems.push(mainItem[0]);
      }
    }
    setAllItemDetailsDB(allItems);
    return allItems;
  };

  const populateFullCart = (
    allItems: ItemDetailsData[],
    itemsIdsAr: number[]
  ) => {
    let tempCheckItems: ItemDetailsData[] = [];
    let tempRecoItems: ItemDetailsData[] = [];
    allItems.map((item) => {
      if (itemsIdsAr.includes(item.id)) {
        tempCheckItems.push(item);
      } else {
        tempRecoItems.push(item);
      }
    });
    setCheckoutItems(tempCheckItems);
    setRecommendedItems(tempRecoItems);
  };

  const handleRemoveItem = (itemId: number) => {
    setCheckoutItemIds((prevItems) =>
      prevItems.filter((currId) => currId !== itemId)
    );
    setCheckoutItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
    let tempRecoItems: ItemDetailsData[] = [];
    let foundItem = false;
    for (let i = 0; !foundItem && i < allItemDetailsDB.length; i++) {
      if (allItemDetailsDB[i].id === itemId) {
        tempRecoItems.push(allItemDetailsDB[i]);
        foundItem = true;
      }
    }
    if (tempRecoItems.length > 0)
      setRecommendedItems((prevItems) => [...prevItems, tempRecoItems[0]]);
  };

  const handleAddItemFromRecommended = (itemId: number) => {
    setCheckoutItemIds((prevIds) => [...prevIds, itemId]);
    setRecommendedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
    let tempCartItems: ItemDetailsData[] = [];
    let foundItem = false;
    for (let i = 0; !foundItem && i < allItemDetailsDB.length; i++) {
      if (allItemDetailsDB[i].id === itemId) {
        tempCartItems.push(allItemDetailsDB[i]);
        foundItem = true;
      }
    }
    if (tempCartItems.length > 0)
      setCheckoutItems((prevItems) => [...prevItems, tempCartItems[0]]);
  };

  useEffect(() => {
    paymentCallbackFunction();
    if (itemId) {
      const logBody: LogEventBody = {
        studentUserID: student_username,
        eventName: "add_to_cart",
        productIDs: [parseInt(itemId)],
      };
      logEvent(logBody).then(() => {
        getAndSetAllItemsDB(parseInt(itemId)).then((allItems) => {
          let itemsIdsAr: number[] = [parseInt(itemId)];
          //now add all checkout items
          setCheckoutItemIds(itemsIdsAr);
          populateFullCart(allItems, itemsIdsAr);
        });
      });
    }
  }, []);

  const getSessionIdBD = async (
    orderIds: number[]
  ): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
    let orderNote = "";
    for (let i = 0; i < orderIds.length; i++) {
      orderNote += orderIds[i];
      if (i + 1 < orderIds.length) orderNote += ",";
    }
    // orderNote = "27,28";
    const RequestData = {
      orderNote: orderNote,
    };
    let responseGetLinks = await getPaymentLinkForOrder(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };
  const getSessionForDoubtClearing = async (
    orderIds: string
  ): Promise<AxiosResponse<ApiResponseGetPaymentLink>> => {
    let orderNote = "";
    for (let i = 0; i < orderIds.length; i++) {
      orderNote += orderIds[i];
      if (i + 1 < orderIds.length) orderNote += ",";
    }
    // orderNote = "27,28";

    const RequestData = {
      uniqueBookingID: undiqueId,
    };
    let responseGetLinks = await getPaymentLinkForDoubtClearing(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };
  const getSessionForNFOSessionsLink = async (): Promise<
    AxiosResponse<ApiResponseGetPaymentLink>
  > => {
    const RequestData = {
      studentID: student_username,
      eventID: nfoSessionseventId,
    };
    let responseGetLinks = await getPaymentLinkForNFOSessions(RequestData);
    console.log("responseGetLinks = ", responseGetLinks);
    return responseGetLinks;
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };
  const handlePaymentClick = async (orderIds: number[]) => {
    let itemIdsString: string = "";
    for (let i = 0; i < checkoutItems.length; i++) {
      itemIdsString += checkoutItems[i].id;
      if (i + 1 !== checkoutItems.length) {
        itemIdsString += "-";
      }
    }
    try {
      setLoading(true);

      const response = undiqueId
        ? await getSessionForDoubtClearing(undiqueId)
        : itemId === "29"
        ? await getSessionForNFOSessionsLink()
        : await getSessionIdBD(orderIds);

      //@ts-ignore
      console.log(response.data.data.links[1].parameters.bdorderid);
      //@ts-ignore
      if (response.data.status == "Success") {
        var flow_config = {
          //@ts-ignore
          merchantId: response.data.data.links[1].parameters.mercid,
          //@ts-ignore
          bdOrderId: response.data.data.links[1].parameters.bdorderid,
          //@ts-ignore
          authToken: response.data.data.links[1].headers.authorization,
          childWindow: true,
          //@ts-ignore
          // returnUrl: `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?order_id=${response.data.data.orderID}&item_ids=${itemIdsString}`,
          returnUrl: `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?order_id=${response.data.data.orderID}&item_ids=${itemIdsString}&userName=${student_username}`,
          retryCount: 3,
          prefs: {
            payment_categories: ["card", "nb", "upi"],
            // allowed_bins: ["540000", "400000"],
          },
          netBanking: {
            showPopularBanks: "Y",
            popularBanks: ["Kotak Bank", " AXIS Bank [Retail]"],
          },
        };

        var responseHandler = function (txn: {
          status: string;
          response: string;
        }) {
          console.log("callback received status:: ", txn.status);
          console.log("callback received response:: ", txn.response);
        };

        var theme_config = {
          sdkPrimaryColor: "#023b8f",
          sdkAccentColor: "#023b8f",
          sdkBackgroundColor: "#FFF",
          sdkBannerColor: "#FFF",
        };

        var config = {
          responseHandler: responseHandler,
          merchantLogo: merchantLogoString,
          flowConfig: flow_config,
          flowType: "payments",
          themeConfig: theme_config,
        };

        logFirebaseEvent("user_intiates_payment_gateway", {
          user_id_custom: student_username,
          grade: grade,
          school: school,
          itemName: checkoutItems[0].name,
        }).then(() => {
          const logBody: LogEventBody = {
            studentUserID: student_username,
            eventName: "payment_gateway",
            productIDs: checkoutItemIds,
          };
          logEvent(logBody).then(() => {
            //@ts-ignore
            if (window.loadBillDeskSdk) {
              // Assuming you have a config object
              //@ts-ignore
              window.loadBillDeskSdk(config);
              setLoading(false);
            }
          });
        });
      }
    } catch (err) {
      setLoading(false);
      setShowBookingFailed(true);
    }
  };

  return (
    <div className="checkout-summary-page">
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: 100,
            background: "rgba(0,0,0,0.7)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="checkout">
        <UniversalCheckoutHeader
          titleText="Your order summary"
          subtitleText={
            checkoutItems.length +
            " Product" +
            (checkoutItems.length > 1 ? "s " : " ") +
            "Added"
          }
          titleTextClass="universal-text-wrapper"
          subtitleTextClass="universal-div"
        />
        <CartSubtitle
          stateLoading={loading}
          stateCheckoutItems={checkoutItems}
          stateRecommendedItems={recommendedItems}
          cbRemoveProduct={handleRemoveItem}
          cbAddRecommended={handleAddItemFromRecommended}
          cbHandlePaymentClick={handlePaymentClick}
        />

        <CheckoutFooter />
      </div>
      {showBookingFailed && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            backgroundColor: "white",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ alignSelf: "center", justifySelf: "center" }}>
            NFO Expert Assistance booking has failed. Please try again later.
          </div>
          <div
            onClick={() => {
              openInSameTab(
                "https://nfoportal.nationalfinanceolympiad.com/doubt-clearing"
              );
            }}
            className="back-to-portal-button"
          >
            Go back to Student Hub
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutPage;
