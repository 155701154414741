import React, { useContext, useEffect, useState } from "react";
import "./Recommendations.css";
import {
  ItemDetailsData,
  ItemDetailsRecommended,
} from "../../../../Utils/DefinitionsInterfaces";
import ItemInfoModal from "../ItemInfoModal/ItemInfoModal";
import { CartContext } from "../../../../App";

interface RecommendationsProps {
  stateRecommendedItems: ItemDetailsData[];
  cbAddRecommended: (itemId: number) => void;
}

const Recommendations = (props: RecommendationsProps) => {
  const [ItemInfoVisible, setItemInfoVisible] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null);

  const handleCloseItemInfo = () => {
    setCurrentItemIndex(null);
    sessionStorage.removeItem("currentItemIndex");
    setItemInfoVisible(false);
  };

  const handleShowItemInfo = () => {
    setItemInfoVisible(true);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleCloseItemInfo();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const RecoItems = [];
  for (let i = 0; i < props.stateRecommendedItems.length; i++) {
    RecoItems.push(
      <div className="reco-item" key={i + 1}>
        <div className="img">
          <img
            className="image"
            alt="Reco Image"
            src={props.stateRecommendedItems[i].image}
          />
        </div>
        <div className="NFO-sample-papers">
          {props.stateRecommendedItems[i].name}
        </div>
        <div className="reco-row-align">
          <button
            className="product-info"
            onClick={() => {
              setCurrentItemIndex(i);
              sessionStorage.setItem("currentItemIndex", i.toString());
              handleShowItemInfo();
            }}
          >
            <div className="overlap-group-2">
              <div className="rectangle-2" />
              <div className="eye">
                <div className="text-wrapper-6">i</div>
              </div>
            </div>
          </button>
          <div className="additembutton">
            <button
              className="overlap"
              onClick={() => {
                props.cbAddRecommended(props.stateRecommendedItems[i].id);
              }}
            >
              {/* <div className="rectangle-3" /> */}
              <div className="text-wrapper-7">+ Add item</div>
            </button>
          </div>
        </div>
        <ItemInfoModal
          image={
            sessionStorage.getItem("currentItemIndex")
              ? props.stateRecommendedItems[
                  //@ts-ignore
                  parseInt(sessionStorage.getItem("currentItemIndex"))
                ].image
              : ""
          }
          title={
            sessionStorage.getItem("currentItemIndex")
              ? props.stateRecommendedItems[
                  //@ts-ignore
                  parseInt(sessionStorage.getItem("currentItemIndex"))
                ].name
              : ""
          }
          description={
            sessionStorage.getItem("currentItemIndex")
              ? props.stateRecommendedItems[
                  //@ts-ignore
                  parseInt(sessionStorage.getItem("currentItemIndex"))
                ].description
              : ""
          }
          isVisible={ItemInfoVisible}
          currentItemId={
            sessionStorage.getItem("currentItemIndex")
              ? props.stateRecommendedItems[
                  //@ts-ignore
                  parseInt(sessionStorage.getItem("currentItemIndex"))
                ].id
              : 30
          }
          onClose={() => {
            handleCloseItemInfo();
          }}
          cbHandleCloseItemInfo={handleCloseItemInfo}
          cbAddRecommended={props.cbAddRecommended}
        >
          <div></div>
        </ItemInfoModal>
      </div>
    );
  }

  return <div className="recommendations">{RecoItems}</div>;
};

export default Recommendations;
